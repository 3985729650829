@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/fonts';

.root {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    padding: 4.8rem 2.4rem;
    background-color: var(--colors-blue);
    color: var(--colors-cream);

    section {
        max-width: 40rem;
    }
}

.signUp {
    width: 100%;
    padding-bottom: 4.8rem;
    border-bottom: 0.1rem solid rgba(color('cream'), 0.2);
}

.logoAndLinkGroups {
    width: 100%;
    padding: 4.8rem 0;
}

.logoLink {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2.8rem;
}

.logo {
    width: 28.7rem;
    height: 10.6rem;
    color: var(--colors-cream);
}

.tagline {
    margin-top: 1.8rem;
    margin-bottom: 0;
    color: var(--colors-cream);
    font-family: $GTWalsheimUltraBold;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;

    span {
        position: relative;
    }

    sup {
        position: absolute;
        top: 0.05em;
        bottom: 100%;
        margin-left: 0.1em;
        font-family: $GTWalsheim;
        font-size: 1.6rem;
        line-height: 100%;
        vertical-align: middle;
    }
}

.bCorp {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
}

.bCorpInfo {
    display: flex;
    max-width: 19rem;
    align-items: center;
    margin-left: 2rem;
    text-transform: uppercase;
}

.bCorpLogo {
    width: 2.6rem;
    height: 5.3rem;
}

.copyrightWithLegalLinks {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.legalLinks {
    display: flex;
    justify-content: center;
    gap: 0 0.8rem;
}

.privacyPreferences {
    display: flex;
    justify-content: center;
    margin-bottom: 2.4rem;
    gap: 0 0.8rem;
}

a.legalLink,
button.legalLink {
    text-decoration: underline;
    text-transform: inherit;
}

p.disclaimer {
    max-width: 40rem;
    margin-bottom: 0;
    font-size: 1rem;
    text-align: center;
    text-wrap: pretty;
}

@include print {
    .root {
        display: none;
    }
}

@include medium {
    .root {
        padding: var(--block-padding);
        padding-bottom: 4.8rem;

        section {
            max-width: var(--block-max-width);
        }
    }

    .logoLink {
        margin-bottom: 4.8rem;
    }

    .linkGroups {
        display: flex;
        width: 100%;
        justify-content: space-around;
        padding-top: 4.8rem;
        border-top: 0.1rem solid rgba(color('cream'), 0.2);
    }

    .legal {
        width: 100%;
        padding-top: 4.8rem;
        border-top: 0.1rem solid rgba(color('cream'), 0.2);
    }

    .copyrightAndLegal {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

@include large {
    .logoAndLinkGroups {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .logoLink {
        width: 29rem;
        margin-bottom: 0;
    }

    .linkGroups {
        width: 50%;
        justify-content: space-between;
        padding-top: 0;
        padding-left: calc(var(--block-padding-side) / 2);
        border-top: none;
    }

    .legal {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .bCorp {
        max-width: 56rem;
        align-items: center;
        margin-bottom: 0;
    }

    .bCorpLogo {
        width: 3.8rem;
        height: 6.4rem;
    }

    .bCorpInfo {
        display: flex;
        max-width: 22rem;
    }

    .copyrightAndLegal {
        width: 50%;
        align-items: flex-start;
        padding-left: calc(var(--block-padding-side) / 2);
    }

    .copyrightWithLegalLinks {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0 1.3rem;
        text-align: left;
    }

    .legalLinks {
        display: flex;
        justify-content: center;
        gap: 0 0.8rem;
    }

    p.disclaimer {
        max-width: none;
        text-align: left;
    }
}

@include xLarge {
    .signUp {
        padding-bottom: 6rem;
    }

    .logoLink {
        width: 38rem;
    }

    .logo {
        width: 38rem;
        height: 14rem;
    }

    .tagline {
        margin-top: 2.4rem;
        font-size: 2.4rem;
    }

    .bCorpInfo {
        max-width: 36rem;
        margin-left: 3.1rem;
    }

    span.bCorpInfo {
        font-size: 1.6rem;
    }
}

@include xxLarge {
    .logoAndLinkGroups {
        padding: 6rem 0;
    }

    .legal {
        padding-top: 6rem;
    }

    .copyrightWithLegalLinks {
        flex-direction: row;
    }
}
