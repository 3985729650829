@import 'styles/animations';
@import 'styles/fonts';
@import 'styles/breakpoints';

.root {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.header {
    display: flex;
    width: 100%;
    max-width: 32rem;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
}

.headline {
    margin-bottom: 1.2rem;
    font-family: $GTWalsheimUltraBold;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1;
    text-transform: uppercase;
}

.copy {
    max-width: 30rem;
    margin-bottom: 2rem;
    margin-bottom: 0;
    text-wrap: pretty;
}

.formAndSocial {
    width: 100%;
}

.inputWrapper {
    position: relative;
    width: 100%;
}

.input {
    display: flex;
    width: 100%;
    height: 6rem;
    align-items: center;
    justify-content: space-between;
    padding: 1.4rem 6rem 1.4rem 2rem;
    border: none;
    border-radius: 3rem;
    margin-bottom: 1rem;
    background-color: var(--colors-cream);

    &::placeholder {
        color: var(--colors-blue);
        font-weight: 500;
    }

    .inputSuccess & {
        pointer-events: none;

        &::placeholder {
            color: var(--colors-cheeseMexican4Cheese);
        }
    }
}

.submitButton {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 6rem;
    height: 6rem;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: none;
    border-radius: 50%;
    margin: 0;
    background: none;
    color: var(--colors-blue);

    &:disabled {
        display: none;
    }
}

.joinError {
    margin-bottom: 1.2rem;
}

.reCaptchaLegal {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    opacity: 0.5;

    > div {
        color: var(--colors-cream);
        font-size: 1rem;
    }
}

.socialLinks {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 1rem;
}

@include medium {
    .root {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        text-align: left;
    }

    .header,
    .reCaptchaLegal,
    .socialLinks {
        align-items: flex-start;
        justify-content: flex-start;
    }

    .formAndSocial {
        width: 50%;
        padding-left: calc(var(--block-padding-side) / 2);
    }
}

.spinner {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    width: 6rem;
    height: 6rem;
    align-items: center;
    justify-content: center;

    --spinner-color: var(--colors-blue);
}

@include large {
    .root {
    }

    .header {
        width: 50%;
        max-width: none;
        padding-right: calc(var(--block-padding-side) / 2);
    }

    .headline {
        margin-bottom: 2.4rem;
        font-size: 3.6rem;
    }

    .copy {
        max-width: none;
    }

    .reCaptchaLegal {
        > div {
            max-width: none;
            white-space: pre;
        }
    }

    .input {
        height: 8rem;
        padding: 3.2rem 3rem;
        border-radius: 50rem;
        margin-bottom: 1.2rem;
    }

    .submitButton,
    .spinner {
        width: 8rem;
        height: 8rem;
    }
}

@include xxLarge {
    .reCaptchaLegal {
        margin-bottom: 2.4rem;

        > div {
            font-size: 1.2rem;
        }
    }
}
