@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/fonts';

@keyframes up {
    0% {
        opacity: 0;
        transform: translateY(4em);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.headerContainer {
    position: fixed;
    z-index: var(--z-header);
    top: 0;
    left: 0;
    width: 100%;
    transition: transform $fast $easeInOutCubic;

    &.isHidden {
        transform: translateY(-100%);
    }

    &.menuOpen {
        height: 100vh;
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
    }
}

.header {
    display: flex;
    width: 100%;
    height: var(--header-height);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    background-color: var(--colors-blue);
    color: var(--colors-cream);
    font-size: 1rem;
}

.logoSvg {
    width: calc(var(--header-logo-width) * 1rem / 10);
}

.mobileLogoWrapper {
    position: absolute;
    z-index: var(--z-header);
    align-items: center;
    transition: transform $default $easeOutCubic;

    .isLanding & {
        animation: up 0.4s $easeOutCubic calc(1000ms + 70ms * 4) backwards,
            fade-in 0.4s calc(1000ms + 70ms * 4) backwards;
    }

    &--open {
        z-index: 100;
        transform: translateY(var(--header-height)) scale(2.2);
        transform-origin: 45% 0%;
        transition: transform $default $easeOutCubic 0.1s;
    }

    &--backFromSubPanel {
        z-index: 100;
        transform: translateY(var(--header-height)) scale(2.2);
        transition: transform $default $easeOutCubic 0.4s;
    }

    &--subPanelOpen {
        z-index: 100;
        transform: translateY(0%) scale(1);
        transition: transform $default $easeOutCubic 0.1s;
    }
}

.desktopTransitionLogoWrapper {
    position: absolute;
    z-index: var(--z-header);
    display: none;
    align-items: center;
    pointer-events: none;
}

.mobileLogoWrapperTop {
    position: absolute;
    z-index: var(--z-header);
    top: 1rem;
    left: 50%;
    display: none;
    align-items: center;
    transform: translateY(var(--header-height)) scale(2.2);
    transform-origin: 45% 0%;
    transition: transform $default $easeOutCubic 0.4s;
    translate: -50% 0;

    &--backFromSubPanel {
        z-index: 100;
        transform: translateY(var(--header-height)) scale(2.2);
        transition: transform $default $easeOutCubic 0.4s;
    }

    &--subPanelOpen {
        z-index: 100;
        transform: translateY(0%) scale(1);
        transition: transform $default $easeOutCubic 0.1s;
    }
}

// Acts as main Touch Overlay container
.nav {
    position: absolute;
    top: var(--nav-top, 0);
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    min-height: 77em;
    padding: var(--nav-padding, 0);
    background-color: var(--colors-blue);
    color: var(--colors-cream);
    opacity: 0;
    pointer-events: none;
    transform: translateY(-2%); // For animation effect
    transition: opacity $easeOutCubic $default, transform $easeOutCubic $default,
        visibility $easeOutCubic $default $default;
    visibility: hidden;

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-image: url('/images/menu-peak-2.svg');
        background-position-x: center;
        background-position-y: calc(var(--header-height) * -1 - 10rem);
        background-repeat: no-repeat;
        background-size: 1024px;
        content: '';
        opacity: 0;
        transition: opacity $easeOutCubic $default;
    }

    &--open {
        overflow: hidden;
        width: 100%;
        opacity: 1;
        pointer-events: all;
        transform: translateY(0);
        transition: opacity $easeOutCubic $default,
            transform $easeOutCubic $default, visibility $easeOutCubic $default;
        visibility: visible;

        &::before {
            opacity: 1;
        }
    }

    &--subPanelOpen {
        visibility: visible;

        .mobileSubPanel {
            transform: translate(-100%);
        }

        .linksWrapper {
            pointer-events: none;
            transform: translate(-100vw);
        }
    }
}

.inner {
    display: flex;
    height: 100%;
    flex-direction: var(--inner-flex-direction, column);
    align-items: center;
    justify-content: var(--inner-justify-content, flex-start);
    padding: var(--inner-padding, 0);
    background: var(--colors-blue);
    background-blend-mode: overlay, normal, multiply;
    background-position: center top;
    background-repeat: no-repeat, repeat;
    background-size: cover;
    mix-blend-mode: normal;
}

.linksWrapper {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 50rem;
    height: 56.7vmax;
    min-height: 28.2rem;
    flex-direction: column;
    padding: 0 4rem;
    margin-top: clamp(22rem, 60vw, 30rem);
    transition: transform 0.5s 0.2s $easeOutCubic;
}

.rightLinks,
.leftLinks {
    padding: 0;
    margin: 0;
}

.navItem {
    display: flex;
    width: 100%;
    align-items: center;
    border: none;
    border-bottom: 1px solid rgba(#fffce6, 0.2);
    font-size: 2rem;
    isolation: isolate;
    text-align: left;

    button {
        padding: 0;
        border: none;
        margin: 0;
        background: none;
        color: var(--colors-white);

        span {
            text-align: left;
        }
    }

    a,
    button {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        text-decoration: none;

        &::after {
            display: block;
            content: '';
        }

        .linkInner {
            width: 100%;
            padding: 1.6rem 0;
        }
    }

    .isLanding & {
        animation: up 0.4s $easeOutCubic calc(1000ms + var(--delay, 0)) both,
            fade-in 0.4s calc(1000ms + var(--delay, 0)) both;
    }

    &.hasChevron {
        a::after,
        button::after {
            position: absolute;
            right: 0;
            width: 1.1rem;
            height: 2rem;
            background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="11" height="20" viewBox="0 0 11 20" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.76565 0.763L11 9.99735L10.9985 9.99882L11 10.0003L1.76323 19.2371L0.328241 17.8439L8.17335 9.99882L0.330665 2.15613L1.76565 0.763Z" fill="%23FFFCE6"/></svg>');
        }

        .linkLarge {
            display: none;
        }
    }

    &.hasArrow {
        a::after,
        button::after {
            position: absolute;
            right: 0;
            width: 1.3rem;
            height: 1.2rem;
            background: url('data:image/svg+xml,<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="0.467773" y="11.1249" width="15.0139" height="2" transform="rotate(-45 0.467773 11.1249)" fill="%23FFFCE6"/><path d="M2.51337 0.556319L12.4999 0.460876L12.4989 0.599022L12.5 0.599014L12.42 10.4463L10.614 10.4609L10.6816 2.45052L2.5 2.53136L2.51337 0.556319Z" fill="%23FFFCE6"/></svg>');
        }
    }
}

.mobileSubPanel {
    position: fixed;
    top: var(--header-height);
    bottom: 0;
    left: 100%;
    width: 100%;
    height: calc(100% - var(--header-height));
    background: var(--colors-white);
    isolation: isolate;
    transition: all 0.5s 0.2s $easeOutCubic;

    nav {
        display: flex;
        flex-direction: column;
        padding: 0 4rem;
        background-color: var(--colors-white);
    }

    .navItem {
        font-size: 1.8rem;
        font-weight: 500;
    }
}

.circleArrowSolid {
    position: relative;
    top: -0.2rem;
    display: grid;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--colors-blue);
    color: var(--colors-white);
    place-content: center;

    > span {
        width: 0.5rem;
        height: 0.8rem;
    }
}

.mobileSubPanelLink {
    display: flex;
    padding: 1.6rem 0;
    border-bottom: 1px solid rgba(0, 30, 96, 20%);
    color: var(--colors-blue);
    font-size: 1.8rem;
    font-weight: 500;
    gap: 1.2rem;

    &:first-of-type {
        display: flex;
        align-items: center;
        padding: 3.6rem 0 2rem;
        border-bottom: none;
        font-family: $GTWalsheimUltraBold;
        font-size: 2.4rem;
        font-weight: 800;
        line-height: 1.2;
        text-transform: uppercase;
    }
}

.back {
    justify-content: flex-start;
    padding: 1.8rem 4rem;
    border-bottom: 1px solid rgba(0, 30, 96, 10%);
    background: transparent;
    color: var(--colors-blue);

    &::after {
        display: none;
    }

    &::before {
        display: block;
        width: 1.2rem;
        height: 1.9rem;
        margin-right: 1.4rem;
        background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="19" viewBox="0 0 12 19" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.73435 0L0.5 9.23435L0.501516 9.23582L0.5 9.23729L9.73677 18.4741L11.1718 17.0809L3.32665 9.23582L11.1693 1.39313L9.73435 0Z" fill="%23001E60"/></svg>');
        content: '';
    }
}

.mobileSubPanelClose {
    width: 100%;
    background: transparent;
}

.subNav {
    display: none;
}

.logoBg {
    position: absolute;
    display: var(--logoBg-display, block);
    width: 100%;
    min-width: 76.5rem;
    height: auto;
}

.logo {
    position: relative;
    display: flex;
    max-width: 23.4rem;
    margin-top: -9vmax;
    pointer-events: auto;
    transform: translateX(1.2%);
}

.searchBarDesktop {
    display: none;
}

.isNewLabel {
    display: inline-block;
    padding: 0.3rem 0.8rem 0.2rem;
    border-radius: 0.6rem;
    background: rgba(88, 172, 219, 15%);
    color: #58acdb;
    font-family: $GTWalsheimCond;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 0.15rem;
    line-height: 1.2;
    text-transform: uppercase;
}

@include landscape {
    .linksWrapper {
        translate: 0 -3rem;
    }
}

// "Desktop nav"
@include large {
    .header {
        --links-width: auto;
        --tillamapsCTA-display: none;
        --hamburgerIcon-display: none;
    }

    .mobileLogoWrapperTop {
        .isLanding & {
            display: none;
        }
    }

    .inner {
        min-height: 8rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        background: none;
    }

    .mobileSubPanel {
        display: none;
    }

    .nav {
        left: 50%;
        overflow: visible;
        max-width: calc(
            var(--block-max-width) + var(--block-padding-side) +
                var(--block-padding-side)
        );
        height: 8rem;
        min-height: 0;
        flex-direction: row;
        padding: 0;
        background: none;
        opacity: 1;
        pointer-events: auto;
        transform: none;
        translate: -50% 0;
        visibility: visible;
    }

    .linksWrapper {
        position: relative;
        top: auto;
        display: flex;
        width: auto;
        height: 100%;
        min-height: 0;
        align-items: center;
        padding: 0 5rem;
        margin-top: 0;
        pointer-events: all;
        visibility: visible;
    }

    .rightLinks,
    .leftLinks {
        position: absolute;
        display: flex;
        height: 100%;
        box-sizing: border-box;
        flex: 1 0 auto;
        transition: translate $default $easeOutCubic;
        will-change: translate;

        .navItem {
            position: relative;
            display: flex;
            min-width: fit-content;
            height: 100%;
            align-items: left;
            border-bottom: 0;

            a,
            button {
                display: flex;
                align-items: center;
                font-weight: 500;

                .linkInner {
                    padding: 0;
                }
            }

            &.hasChevron {
                a::after,
                button::after {
                    display: none;
                }

                .linkLarge {
                    display: flex;
                }

                .linkSmall {
                    display: none;
                }
            }

            &.hasChevron.isTouch {
                .linkLarge {
                    display: none;
                }

                .linkSmall {
                    display: flex;
                }
            }

            &.hasArrow {
                a::after,
                button::after {
                    position: relative;
                    width: 1.3rem;
                    height: 1.1rem;
                    margin-left: 0.5rem;
                    background-size: 100%;
                }
            }

            &:hover {
                a {
                    background: rgba(255, 255, 255, 10%);
                }
            }

            &.isActive {
                .subNavContainer {
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }

        .subNavContainer {
            opacity: 0;
            pointer-events: none;
            transition: opacity $fast $easeOutCubic;

            &::before {
                position: absolute;
                z-index: 3;
                bottom: -3.6rem;
                left: calc(50% - 0.5rem);
                display: block;
                width: 30px;
                height: 30px;
                border-radius: 0.4rem;
                background: white;
                content: '';
                transform: rotate(45deg) translateX(-50%);
            }
        }

        .subNav {
            position: absolute;
            z-index: 2;
            top: calc(100% + 0.8rem);
            left: -3rem;
            display: flex;
            min-width: 35.6rem;
            flex-direction: column;
            flex-shrink: 0;
            align-items: flex-start;
            padding: 4.5rem 4.8rem 4.8rem;
            border-radius: 3rem;
            background: white;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 10%);

            &::after {
                position: absolute;
                z-index: -1;
                top: -3rem;
                display: block;
                width: 100%;
                height: 100%;
                border-radius: 0.4rem;

                // Broaden mouse out area
                content: '';
            }

            a {
                display: flex;
                justify-content: flex-start;
                padding: 1rem 0;
                border-radius: 0;
                color: var(--colors-blue);
                font-size: 1.8rem;
                gap: 1rem;
                text-align: left;

                &:hover {
                    color: #41b6e6;
                }

                &:hover .circleArrowSolid {
                    background-color: #41b6e6;
                }

                &:first-of-type {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    padding: 0 0 2rem;
                    border-radius: 0;
                    border-bottom: 1px solid rgba(0, 30, 96, 10%);
                    font-family: $GTWalsheimUltraBold;
                    font-size: 2.4rem;
                    font-weight: 800;
                    line-height: 1.2;
                    text-transform: uppercase;
                }

                &:nth-of-type(2) {
                    padding-top: 2.5rem;
                }

                &:last-of-type {
                    padding-bottom: 0;
                }
            }
        }

        a,
        button {
            display: block;
            padding: 0.7rem 2.5rem;
            border-radius: 4rem;
            color: var(--colors-cream);
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 500;
            line-height: 130%; /* 2.08rem */
            text-align: left;
            text-decoration: none;
            text-transform: capitalize;
        }
    }

    .leftLinks {
        right: 5rem;
        translate: -11rem 0;
    }

    .rightLinks {
        left: 5rem;
        translate: 11rem 0;
    }

    .logoContainer {
        z-index: 1;
    }

    .searchBarDesktop {
        right: calc(var(--block-padding-side) - 1rem);
        display: flex;

        .isLanding & {
            animation: up 0.4s $easeOutCubic calc(1000ms + 70ms * 7) both,
                fade-in 0.4s calc(1000ms + 70ms * 7) both;
        }
    }

    .searchBarMobile {
        display: none;
    }

    @include landscape {
        .linksWrapper {
            translate: 0 0;
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
