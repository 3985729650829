@import 'styles/animations';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/mixins';

$search-icon-width: 1.5rem;

.root {
    position: relative;
    display: flex;
    width: calc(100% + 3.6rem);
    align-items: center;
    justify-content: space-between;
    padding: 1.4rem 1.5rem 1.4rem 1.8rem;
    border-radius: 3rem;
    margin-bottom: 1.6rem;
    margin-left: -1.8rem;
    background-color: rgba(255, 255, 255, 10%);
}

.rootInner {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.overlay {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
}

.searchIconWrapper {
    display: none;
    cursor: pointer;

    > svg {
        width: 1.8rem;
        height: 1.8rem;
    }
}

.searchIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
        width: 1.9rem;
        height: 1.9rem;
    }
}

.searchInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0;

    label {
        width: 100%;
    }
}

.searchWrapper {
    width: 100%;
    background-color: transparent;
}

.submitButtonWrapper {
    position: relative;
    border: none;
    appearance: none;
    background-color: transparent;
    color: var(--colors-cream);
    cursor: pointer;
}

.submitButtonMobile {
    width: 1.5rem;
    height: 1.6rem;
    padding: 0;
    border: 0;
    margin: 0;
    background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" fill="none"><path fill="%23FFFCE6" fill-rule="evenodd" d="M12.777 6.611a4.611 4.611 0 1 1-9.222 0 4.611 4.611 0 0 1 9.222 0Zm2 0A6.611 6.611 0 0 1 4.63 12.198l-3.216 3.216L0 14l3.119-3.119a6.611 6.611 0 1 1 11.658-4.27Z" clip-rule="evenodd"/></svg>');
}

.submitButtonDesktop {
    display: none;
    width: 2.1rem;
    height: 2rem;
    padding: 0;
    border: 0;
    margin: 0;
    background: url('data:image/svg+xml,<svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="10.5" cy="10" r="10" fill="%23001E60"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11.7187 10L8.35742 6.82546L9.53394 5.71429L14.0717 10L9.53394 14.2857L8.35742 13.1746L11.7187 10Z" fill="%23FFFCE6"/></svg>');
}

.input {
    width: 100%;
    min-height: 2.2rem;
    padding: 0;
    border: 0;
    background-color: transparent;
    color: var(--colors-cream);

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: rgba(color('cream'), 0.5);
        font-weight: 500;
    }
}

@include large {
    .root {
        position: absolute;
        right: 2.4rem;
        width: 3.7rem;
        height: 3.7rem;
        padding: 0;
        margin: 0;
        background: none;
        cursor: pointer;

        &.isOpen {
            background: rgba(255, 255, 255, 10%);

            .overlay {
                opacity: 1;
                pointer-events: auto;
            }
        }
    }

    .searchIconWrapper {
        display: flex;

        > svg {
            width: 1.9rem;
            height: 1.9rem;
        }
    }

    .submitButtonMobile {
        display: none;
    }

    .submitButtonDesktop {
        display: flex;
    }

    .searchIcon {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .rootInner {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .overlay {
        position: absolute;
        z-index: var(--z-searchbar);
        top: 6.7rem;
        display: flex;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s $easeOutCubic;

        &::before {
            position: absolute;
            z-index: 3;
            bottom: -2.8rem;
            left: calc(50% - 0.5rem);
            display: block;
            width: 30px;
            height: 30px;
            border-radius: 0.4rem;
            background: white;
            content: '';
            transform: rotate(45deg) translateX(-50%);
        }
    }

    .submitButtonWrapper {
        position: absolute;
        right: 4.8rem;
        bottom: 1.6rem;
        display: flex;
        height: calc(100% - 3rem);
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .searchWrapper {
        width: 100%;
        background: var(--colors-white);
        box-shadow: 0 5px 20px rgba(188, 185, 151, 20%);
    }

    .searchInput {
        position: absolute;
        top: 0;
        right: -4rem;
        display: flex;
        width: 49.6rem;
        height: 13.3rem;
        padding: 4.8rem;
        border-radius: 3rem;
        background: var(--Main-White, #fff);
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 10%);

        &::after {
            position: absolute;
            z-index: -1;
            top: -4rem;
            right: 0;
            display: block;
            width: 40%;
            height: 100%;
            border-radius: 0.4rem;

            // Broaden mouse out area
            content: '';
        }
    }

    .input {
        width: 100%;
        min-height: 4.8rem;
        padding: 0;
        color: var(--colors-blue);

        &::placeholder {
            color: rgba(color('blue'), 0.5);
            font-weight: 500;
        }

        @include bottom-divider;
    }
}

@keyframes open {
    0% {
        opacity: 0;
        transform: translateY(30%);
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes close {
    0% {
        opacity: 1;
        transform: translateY(0%);
    }

    100% {
        opacity: 0;
        transform: translateY(30%);
    }
}

@keyframes mobile-open {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

@keyframes mobile-close {
    0% {
        opacity: 1;
        transform: translateX(0%);
    }

    100% {
        opacity: 0;
        transform: translateX(100%);
    }
}
