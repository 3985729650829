@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/breakpoints';
@import 'styles/animations';
@import 'styles/fonts';

@keyframes fade-up {
    0% {
        opacity: 0;
        transform: translateY(5rem);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.root {
    position: absolute;
    right: 2.5rem;
    display: var(--hamburgerIcon-display, block);
    width: 2.2rem;
    height: 2.2rem;
    border: none;
    appearance: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    transform: rotate(0deg);
    transition: 0.5s $easeInOut;

    &.showIntro {
        animation: fade-up $default 1.55s both;
    }

    > span {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 0.2rem;
        background-color: var(--colors-cream);
        opacity: 1;
        transform: rotate(0deg);
        transition: 0.25s $easeInOut;

        &:nth-child(1) {
            top: 0;
        }

        &:nth-child(2),
        &:nth-child(3) {
            top: 0.9rem;
        }

        &:nth-child(4) {
            top: 1.8rem;
        }
    }

    &.open span:nth-child(1) {
        top: 0.9rem;
        left: 50%;
        width: 0;
    }

    &.open span:nth-child(2) {
        transform: rotate(45deg);
    }

    &.open span:nth-child(3) {
        transform: rotate(-45deg);
    }

    &.open span:nth-child(4) {
        top: 0.9rem;
        left: 50%;
        width: 0;
    }
}
